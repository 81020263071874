import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-detail-promotion',
  templateUrl: './detail-promotion.component.html',
  styleUrls: ['./detail-promotion.component.scss']
})
export class DetailPromotionComponent {
  @Input() rowInfos: { [x:string]: any } = new Map();
  @Input() userInfos: { [x:string]: any } = new Map();
  @Input() projectInfos: { [x:string]: any } = new Map();
  @Output() updatePromotion = new EventEmitter<any>();
  @Output() inProgress = new EventEmitter<any>();
}


<div fxLayout="row" fxLayout.xs="column">
    <div class="class-mat-field" fxFlex="16.66">
        <mat-select placeholder="Country" [formControl]="countryControl" class="multiselect" (selectionChange)="updateInstitution('vca_filter_' + pageId)" multiple>
            <mat-option [value]="element['id']" *ngFor="let element of countries; let i = index">
                <span class="mat-option-content">{{element['full_name']}}</span>
                <span class="mat-option-checkbox">
                    <mat-icon *ngIf="selectedElement(countryControl, element)" class="mat-icon material-icons check-icon" fontIcon="done"></mat-icon>
                </span>
            </mat-option>
        </mat-select>
    </div>
    <div class="separator"></div>
    <div class="class-mat-field" fxFlex="16.66">
        <mat-select placeholder="Institution" [formControl]="institutionControl" class="multiselect" (selectionChange)="updateStructure('vca_filter_' + pageId)" multiple>
            <mat-option [value]="element['id']" *ngFor="let element of institutions; let i = index">
                <span class="mat-option-content">{{element['full_name']}}</span>
                <span class="mat-option-checkbox">
                    <mat-icon *ngIf="selectedElement(institutionControl, element)" class="mat-icon material-icons check-icon" fontIcon="done"></mat-icon>
                </span>
            </mat-option>
        </mat-select>
    </div>
    <div class="separator"></div>
    <div class="class-mat-field" fxFlex="16.66">
        <mat-select placeholder="Structure" [formControl]="structureControl" class="multiselect" (selectionChange)="updateDepartment('vca_filter_' + pageId)" multiple>
            <mat-option [value]="element['id']" *ngFor="let element of structures; let i = index">
                <span class="mat-option-content">{{element['full_name']}}</span>
                <span class="mat-option-checkbox">
                    <mat-icon *ngIf="selectedElement(structureControl, element)" class="mat-icon material-icons check-icon" fontIcon="done"></mat-icon>
                </span>
            </mat-option>
        </mat-select>
    </div>
    <div *ngIf="showDepartement" class="separator"></div>
    <div *ngIf="showDepartement" class="class-mat-field" fxFlex="16.66">
        <mat-select placeholder="Department" [formControl]="departmentControl" class="multiselect" (selectionChange)="setDepartment()" multiple>
            <mat-option [value]="element['id']" *ngFor="let element of departments; let i = index">
                <span class="mat-option-content">{{element['full_name']}}</span>
                <span class="mat-option-checkbox">
                    <mat-icon *ngIf="selectedElement(departmentControl, element)" class="mat-icon material-icons check-icon" fontIcon="done"></mat-icon>
                </span>
            </mat-option>
        </mat-select>
    </div>
    <div class="separator"></div>
    <div class="class-mat-field" fxFlex="16.66">
        <mat-select class="class-select-field" placeholder="Mode" [formControl]="modeControl" class="multiselect" multiple>
            <mat-option [value]="element['id']" *ngFor="let element of modes; let i = index">
                <span class="mat-option-content">{{element['value']}}</span>
                <span class="mat-option-checkbox">
                    <mat-icon *ngIf="selectedElement(modeControl, element)" class="mat-icon material-icons check-icon" fontIcon="done"></mat-icon>
                </span>
            </mat-option>
        </mat-select>
    </div>
    <div class="separator"></div>
    <div class="class-mat-field" fxFlex="16.66"  fxLayoutAlign="center center">
        <button class="button-validate-small" (click) = "chargeData()">Validate</button>
    </div>
</div>
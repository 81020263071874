import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import config from 'config/default.json';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { TableComponent } from 'src/app/back-office/components/table/table.component';
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import { ProjectSettingComponent } from 'src/app/back-office/components/project-content/project-setting/project-setting.component';
import { ProjectSplitingComponent } from 'src/app/back-office/components/edit-project/project-spliting/project-spliting.component';
import { ProjectEditingComponent } from 'src/app/back-office/components/edit-project/project-editing/project-editing.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-project-content',
  templateUrl: './project-content.component.html',
  styleUrls: ['./project-content.component.scss', '../../components/table/table.component.scss']
})
export class ProjectContentComponent extends TableComponent{
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,
    public sanitizer: DomSanitizer) {
      super(sanitizer);
  }
  userControl = new FormControl();
  userInputControl = new FormControl();
  startDateInputControl = new FormControl();
  endDateInputControl = new FormControl();
  allUsers:any = [];
  @Input() userInfos: { [x:string]: any } = new Map();
  @Input() data:{[x: string]: any}[]= [];
  @Input() platformInfos: { [x:string]: any } = new Map();

  onlyMyProject:boolean = false;
  totalContent:number = 0;
  totalEdited:number = 0;
  totalCLassified:number = 0;
  totalWords:number = 0;

  ngOnInit(): void {
    this.userInputControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query) => {
        this.sharedService.sendData({in_progress: true});
        return this.apiService.queryData(config.master.ndapi.url+'/enovsky/web/administration/search_user', {search_key: query})
      })
    ).subscribe((options) => {
      this.allUsers = options;
      this.sharedService.sendData({in_progress: false});
    });
    this.buildData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange) this.buildData();
  }
  resetDate(){
    this.startDateInputControl.setValue(null);
    this.endDateInputControl.setValue(null);
  }
  chargeData(){
    let startDate = this.startDateInputControl.value;
    let endDate = this.endDateInputControl.value;
    
    let formatter = new Intl.DateTimeFormat('fr-FR', {year: 'numeric', month: '2-digit', day: '2-digit'});
    startDate = formatter.format(new Date(startDate)).split("/").reverse().join("-");
    endDate = formatter.format(new Date(endDate)).split("/").reverse().join("-");

    let dates = [startDate, endDate];
    dates = dates.filter(el => el != "1970-01-01");
    dates = dates.length > 1? dates: (dates.length == 1? [dates[0], dates[0]]: [null, null]);
   
    let data = {
      id_user: this.userInfos['id_user'], 
      id_user_filter: this.userControl.value,
      dates: dates,
      platform_infos: this.platformInfos
    }
    this.sharedService.sendData({in_progress: true});
    this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/project/load', data).subscribe((response)=>{
      this.data = response;
      this.buildData();
      this.sharedService.sendData({in_progress: false});
    })
  
  }
  buildData(){
    this.dataSource = this.data.map((row) => {
      row['edited_percent'] = row['nb_content'] > 0?  Math.round((row['nb_edited'] / row['nb_content']) * 100 * 100) / 100: 0;
      row['classified_percent'] = row['nb_content'] > 0?  Math.round((row['nb_classified'] / row['nb_content']) * 100 * 100) / 100: 0;
      row['imported_percent'] = row['nb_content'] > 0?  Math.round((row['nb_imported'] / row['nb_content']) * 100 * 100) / 100: 0;
      return row;
    });
    this.showedRows = this.dataSource;
    this.updateTotals()
  }
  updateTotals(){
    this.totalContent = this.showedRows.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue['nb_content']), 0);
    this.totalEdited = this.showedRows.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue['nb_edited']), 0);
    this.totalCLassified = this.showedRows.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue['nb_classified']), 0);
    this.totalWords = this.showedRows.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue['nb_word']), 0);
  }
  toggleOnlyMyProject(){
    this.onlyMyProject = !this.onlyMyProject;
    let value = this.onlyMyProject? this.userInfos['first_name']+' '+this.userInfos['last_name']:'';
    this.applyFilter(value, 'user_edit_splitter');
    this.updateTotals()
  }
  projectOption(rowInfos:{ [x:string]: any }){
    const dialogRef = this.dialog.open(ProjectSettingComponent, {
      width: '70%',
      position: {top: '30px' },
      data: {row_infos: rowInfos, user_infos: this.userInfos, platform_infos: this.platformInfos}
    });
    dialogRef.afterClosed().subscribe((response:any) => {
      if(response && response && response.row){
        let result = response.row;
        this.dataSource = this.dataSource.map((element:any) => {
          if (element.id == result.id) {
            element.project_name = result.project_name;
            element.id_user_splitter = result.id_user_splitter;
            element.id_user_editor = result.id_user_editor;
            element.splitter = result.splitter;
            element.editor = result.editor;
            element.last_task = result.last_task;
            element.state = result.state;
            element.user_edit_splitter = result.user_edit_splitter
          }
          return element;
        });
        this.showedRows = this.showedRows.map((element:any) => {
          if (element.id == result.id) {
            element.project_name = result.project_name;
            element.id_user_splitter = result.id_user_splitter;
            element.id_user_editor = result.id_user_editor;
            element.splitter = result.splitter;
            element.editor = result.editor;
            element.last_task = result.last_task;
            element.state = result.state;
            element.user_edit_splitter = result.user_edit_splitter
          }
          return element;
        });
      }
      if(response && response && response.nb_word){
        /* const indexFound = this.showedRows.findIndex((element:any) => element.id === response.id);
        this.showedRows[indexFound]?.['nb_word'] = response.nb_word; */
        this.showedRows = this.showedRows.map((element:any) => {
          if (element.id == response.id) element.nb_word = response.nb_word;
          return element;
        });
        this.dataSource = this.dataSource.map((element:any) => {
          if (element.id == response.id) element.nb_word = response.nb_word;
          return element;
        });
      }
      if(response && response.action == 'delete_project'){
        this.dataSource = this.dataSource.filter((el:any)=> el.id != response.id_project_deleted);
        this.showedRows = this.showedRows.filter((el:any)=> el.id != response.id_project_deleted);
      }
    });
    
  }
  openSplitingEditing(rowInfos:{ [x:string]: any }){
    this.sharedService.sendData({in_progress: true});
    if(['splitting', 'splitted'].includes(rowInfos['state'])){
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/project/split_detail', {id_project: rowInfos['id'], platform_infos: this.platformInfos}).subscribe((response:any)=>{
        if(response.status){
          const dialogRef = this.dialog.open(ProjectSplitingComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            panelClass: 'full-screen-modal',
            data: {project: rowInfos, raw_data: response['raw_data'], content_data: response['content_data'], user_infos: this.userInfos, platform_infos: this.platformInfos}
          });
          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
          });
        }
        this.sharedService.sendData({in_progress: false});
      })
    }else
    
    if(rowInfos['state'] == 'editing'){
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/project/edit_detail', {id_project: rowInfos['id'], platform_infos: this.platformInfos}).subscribe((response:any)=>{
        if(response.status){
          const dialogRef = this.dialog.open(ProjectEditingComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            panelClass: 'full-screen-modal',
            data: {project: rowInfos, content_data: response['content_data'], user_infos: this.userInfos, platform_infos: this.platformInfos, source: 'project'}
          });
          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
          });
        }
        this.sharedService.sendData({in_progress: false});
      })
    }else this.helper.showToast('Project not assigned for splitting...')
    
    
  }
  applyFilterLocal(element:any, column:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applyFilter(element.value, column);
    this.updateTotals()
    this.cdRef.detectChanges();
  }
  applySortLocal(element:any, column:string, type:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applySort(element, column, type);
    this.updateTotals()
    this.cdRef.detectChanges();
  }
}

import { Component, Input, Pipe, PipeTransform, SecurityContext} from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements PipeTransform{
  
  constructor(
    public sanitizersupper: DomSanitizer,) {
  }
  dataSource: {[x: string]: any}[] = [];
  selectedRows: {[x: string]: any}[] = [];
  showedRows: {[x: string]: any}[] = [];

  sortCounter: number = 0;
  sortValues: {[x:string]: any } = {};
  filterValues : {[x:string]: any } = {};

  transform (value: string): SafeHtml {
    return this.sanitizersupper.bypassSecurityTrustHtml(value);
  }
  
  selectAll(element: any) {
    if (element.checked) {
      this.showedRows.map((element:any) => {
        element.checked = true;
        return element;
        });
    } else {
      this.showedRows.map((element:any) => {
        element.checked = false;
        return element;
        });
    }
    console.log(this.selectedRows)
  }
  
  selectRow(element: any, indexShow:number) {
    if (element.checked) {
      this.showedRows[indexShow]['checked'] = true;
    } else {
      this.showedRows[indexShow]['checked'] = false;
    }
  }
  selectRows(){
    return this.showedRows.filter((element:any) => {
      if (element.checked) return element;
      });

  }
  
  applyFilter(value:string, column:string){
    this.filterValues[column] = value;
    Object.keys(this.filterValues).forEach(element => {if(this.filterValues[element] == "") delete this.filterValues[element] });
    this.applyFilterSort();
  }

  applySort(element:any, column:string, type:string){
    let sortedValue = element.getAttribute('sorted')
    sortedValue = sortedValue==''?'asc':(sortedValue=='asc'?'desc':'')
    let rotateAngle = sortedValue==''?180:(sortedValue=='asc'?-180:0)
    element.setAttribute("sorted", sortedValue);
    if(sortedValue != '') element.style.color = "black"; else element.style.color = "darkgrey";
    element.style.transform="rotateX("+rotateAngle+"deg)";

    let pos = sortedValue == 'asc'? this.sortCounter++ :(sortedValue=='desc'?this.sortValues[column]['pos'] : 0)

    this.sortValues[column] = {pos: pos, sort: sortedValue, type: type};
    Object.keys(this.sortValues).forEach(element => {if(this.sortValues[element].sort == "") delete this.sortValues[element] });

    this.applyFilterSort();

  }
  applyFilterSort(){
    let showedRows = JSON.parse(JSON.stringify(this.dataSource));

    //Filter Data
    let filterValues = Object.entries(this.filterValues);
    if(filterValues.length > 0){
      showedRows = showedRows.filter((row:any) => {
        let findRow = true;
        filterValues.forEach(element=>{
          let column = element[0];
          let value = this.normalizeString(element[1]);
          let text = row[column]? this.normalizeString(row[column]):'';
          let columnFind = column != 'all_column'? text.includes(value): this.normalizeString(Object.values(row).join(' ')).includes(value);
          
          findRow = findRow && columnFind;
        })
        return findRow;
      });
    }

    //Sort Data 
    let sortValues = Object.entries(this.sortValues)
    if(sortValues.length > 0){
      sortValues.sort((a, b) => { return a[1].pos - b[1].pos; });

      showedRows.sort((a:any, b:any) => {
        let compare = 0;
        sortValues.forEach(element=>{
            let column = element[0];
            let sort = element[1].sort;
            let type = element[1].type;
            let columnCompare;
            let valueA =  a[column]? a[column]:'';
            let valueB =  b[column]? b[column]:'';
            if(type == 'number') columnCompare = sort == 'asc'? valueA - valueB : valueB - valueA;
            if(type == 'string'|| type == 'date') columnCompare = sort == 'asc'? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            compare = compare || columnCompare;
          })

          return compare
      });
    }
    if(sortValues.length > 0 || filterValues.length > 0)
    this.showedRows = showedRows;
    else this.showedRows = this.dataSource;
    
  }
  normalizeString(str:string){
    return str.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  }
  removeDetailsRows(tableBody:any){
    Array.from(tableBody.children).forEach((element:any)=>{
      if(element.getAttribute('detail')) element.remove()
    });
  }

  stateTagsDisplay(value:string) {
    const tagsDiv = document.createElement('div');
    const tags = value.toString().replace('splited,','').split(',').filter(tag => tag.trim() !== '').map(tag => tag.trim());
    let colors:any = {
        'converted': 'gray',
        'archived': 'gray',
        'raw': 'gray',
        'classified': '#00B19e',
        'edited': '#0094bc',
        'true': 'green',
        'false': 'gray'
    }
    tags.forEach((tag:string) => {
        const tagElement = document.createElement('span')
        tagElement.classList.add('btn-primary', 'btn-sm')
        tagElement.style.margin = '1px';
        tagElement.style.padding = '3px';
        tagElement.style.borderRadius = "4px"
        tagElement.style.color = 'white';
        tagElement.style.backgroundColor = colors[tag];
        tagElement.innerText = tag;
        tagsDiv.appendChild(tagElement);
    })
    
    return this.sanitizersupper.bypassSecurityTrustHtml(tagsDiv.innerHTML);
 } 
}

import { NgModule, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, Inject, Renderer2, ElementRef} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import config from 'config/default.json';
@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class SplittedModule { 
  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private helper: HelperModule,
    private apiService: ApiServiceModule,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    ) {}

    that: any;
    cleanedModule:any;
    pdfContainer: any;
    config = config;

    init(that:any, cleanedModule:any){
      
      this.that = that;
      this.cleanedModule = cleanedModule;
    }
    showSplitted(){
      console.log(this.that.contentData)
      if(this.that.contentData.length){
        this.that.splitingActive = false;
        this.that.cleanedActive = false;
        this.that.splittedActive = true;

        this.that.activatePage('task-splitted')
        this.pdfContainer = this.elRef.nativeElement.querySelector('.show_content_splited');
        
        this.showPages(this.that.contentData);
      }else this.helper.showToast('Splited content not founed...');

      
    }
    computeClean(){
      if(this.that.contentData.length){
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '50%',
          position: {top: '30px' },
          data: {page: 'confirmation', text: 'This action will erase your previous Clean Build... Continue ?'}
        });
        dialogRef.afterClosed().subscribe((toContinue:boolean) => {
          if(toContinue){
            this.that.inProgress = true;
            let contentsInfos = {
              platform_infos: this.that.platformInfos,
              user_infos: this.that.userInfos,
              project_infos: this.that.projectInfos,
              content_data: this.that.contentData
            };
            const formData = new FormData();
            formData.append('data', JSON.stringify(contentsInfos));
            
            this.apiService.queryData(config.master.flapi.url + '/enovsky/clean_content', formData).subscribe((response:any)=>{
              this.that.inProgress = false;
              if(response.status){
                response.data.forEach((row:any) => {
                  let objIndex = this.that.contentData.findIndex(((element:any) => element.id_content == row.at(-1)));
                  this.that.contentData[objIndex].clean_file_path = row[0];
                })
                this.cleanedModule.showCleaned()
              }else this.helper.showToast(response.message);
            }) 
          }
        });
      }
      else this.helper.showToast('Clean content not created...');

      
    }
    async showPages(data: { [x:string]: any }[]){
      
      this.that.inProgress = true;
      this.that.removeChildsFromElement(this.pdfContainer);
      this.that.PDFJS.GlobalWorkerOptions.workerSrc = 'assets/pdf/pdf.worker.js';
      let totalDocuments = data.length;
      for(let index = 0; index < totalDocuments; index++){
        let url = this.config.master.ndapi.url + '/' + this.that.platformInfos['root']+'/'+data[index]['raw_file_path'];
        
        //Get file
        try {
          const pdf = await this.that.PDFJS.getDocument(url).promise;
          const totalPages = pdf.numPages;
          let contentPages:any = Array(totalPages).fill({});
          for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            let that = this;
            const page = await pdf.getPage(pageNumber);
            //Prepare Canvas
            var scale = 1;
            var viewport = page.getViewport({ scale: scale});
            var canvas = that.renderer.createElement('canvas');
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            let canvasDivContainer = that.renderer.createElement('div');
            let canvasDiv = that.renderer.createElement('div');
            canvasDiv.style.width = viewport.width+'px';
            canvasDiv.style.height = viewport.height+'px';
            canvasDivContainer.classList.add("canvasDivContainerSplit");
            canvasDiv.classList.add("canvasDiv");

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            //Header
            let canvasDivParent = that.renderer.createElement('div');
            let header = that.renderer.createElement('div');
            let middleSide = that.renderer.createElement('div');
            let leftSide = that.renderer.createElement('div');
            let rightSide = that.renderer.createElement('div');
            let topicNameInput = that.renderer.createElement('input');
            let separator = that.renderer.createElement('div');

            header.classList.add("page_header");
            leftSide.classList.add("page_header_left");
            middleSide.classList.add("page_header_middle");
            rightSide.classList.add("page_header_right");
            topicNameInput.classList.add("col-input-name");
            separator.classList.add("line_separator");
            
            middleSide.textContent = 'Content: '+(index + 1)+' - Total pages: '+totalPages;
            topicNameInput.value = data[index]['content_name'];
            rightSide.appendChild(topicNameInput)
            var renderContext = { canvasContext: context, viewport: viewport };
            await page.render(renderContext).promise;

            header.append(leftSide, middleSide, rightSide);

            contentPages[pageNumber - 1] = {page_num: pageNumber, rotation: 0};

            canvasDiv.append(canvas);
            canvasDivContainer.appendChild(canvasDiv);

            topicNameInput.addEventListener("change", (event:any) => {
              that.that.contentData[index].content_name = event.target.value;
            });
            if(pageNumber == 1) canvasDivParent.append(header, canvasDivContainer);
            else canvasDivParent.append(separator, canvasDivContainer);

            that.adjustPageRotation(that, canvasDiv, index, pageNumber - 1);

            that.pdfContainer.appendChild(canvasDivParent);
              
          }
          this.that.inProgress = false;
          this.that.contentData[index]['pages'] = contentPages;
          
        }catch (error) {
          // Handle errors here
          console.error(error);
        }
  
  
      
    }
    
  }
  adjustPageRotation(that:any, canvasDiv:any, contentNumber:number, pageNumber:number){
    var isDragging = false;
    var prevMouseAngle = 0;
    var initialRotation = 0;
  
    // Event listener for mouse down
    canvasDiv.addEventListener('mousedown', function(event:any) {
      isDragging = true;
      prevMouseAngle = getMouseAngle(event);
    
      canvasDiv.style.cursor = 'grabbing';
    });
  
    // Event listener for mouse up
    canvasDiv.addEventListener('mouseup', function() {
      isDragging = false;
      canvasDiv.style.cursor = 'grab';
      initialRotation = getRotationAngle(canvasDiv);
      that.that.contentData[contentNumber].pages[pageNumber].rotation = initialRotation;
    });
  
    // Event listener for mouse move
    canvasDiv.addEventListener('mousemove', function(event:any) {
      if (isDragging) {
        var currentMouseAngle = getMouseAngle(event);
        var angleDiff = currentMouseAngle - prevMouseAngle;
        var newRotation = initialRotation + angleDiff;
        canvasDiv.style.transform = 'rotate(' + newRotation + 'deg)';
      }
    });
  
    // Helper function to calculate the angle based on mouse position
    function getMouseAngle(event:any) {
      var rect = canvasDiv.getBoundingClientRect();
      var centerX = rect.left + rect.width / 2;
      var centerY = rect.top + rect.height / 2;
      var angle = Math.atan2(event.clientY - centerY, event.clientX - centerX) * (180 / Math.PI);
      return angle;
    }
  
    // Helper function to retrieve the current rotation angle of the div
    function getRotationAngle(element:any) {
      var transform = window.getComputedStyle(element).getPropertyValue('transform');
      var values = transform.split('(')[1].split(')')[0].split(',');
      var a = Number(values[0]);
      var b = Number(values[1]);
      var angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
      return angle;
    }
  }
  
  
}

<mat-sidenav-container style="height: 100vh;">
    <mat-sidenav #sidenav class="mat-sidenav-style">
      <mat-nav-list>
        <app-side-enovsky *ngIf="currentRoute == ''" [userInfos]="userInfos"></app-side-enovsky>
        <app-side-virtualcampus *ngIf="currentRoute == 'virtualcampus'" [userInfos]="userInfos"></app-side-virtualcampus>
        <app-side-virtualclass *ngIf="currentRoute == 'virtualclass'" [userInfos]="userInfos"></app-side-virtualclass>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div >
          <mat-toolbar class="mat-elevation-z2 toolbar-style">
            <mat-icon style="cursor: pointer;" aria-hidden="false" fontIcon="menu" title="Mop" (click)="sidenav.open()"></mat-icon>
            <div class="spacer"></div>
            <div class="spacer"></div>
            <a [routerLink]="'virtualcampus'" [ngClass]="{'active-page' : currentRoute == 'virtualcampus'}" class="toolbar-menu" (click)="shareUserData()">University (VCA)</a>
            <a [routerLink]="'virtualclass'" [ngClass]="{'active-page' : currentRoute == 'virtualclass'}" class="toolbar-menu" (click)="shareUserData()">Secondary (VCL)</a>
            <a [routerLink]="''" class="dropdown profile">
                <img src="assets/logos/icon_enovsky.png">
                <div class="dropdown-content">
                  <li>
                    <p style="margin: 0; font-size: medium;">{{userInfos? userInfos['first_name']:''}}</p>
                    <p style="margin: 0; ">{{userInfos? userInfos['email']: ''}}</p>
                  </li> 
                  <li (click)="connectAsUser()">
                    <p style="margin: 0; font-size: medium;">{{connectedUser['connect_msg']}}</p>
                    <p style="margin: 0; color: green;">{{connectedUser['email']}}</p>
                  </li>
                  <li style="font-weight: bold; text-align: center;" (click)="signOut()">Sign out</li>
                  <div style="text-align: center;">v1.2.0</div>
                </div>
            </a>
            
          </mat-toolbar>
          <mat-progress-bar *ngIf="inProgress" class="progress_bar" mode="buffer"></mat-progress-bar>
          <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  
    
    
  </mat-sidenav-container>
  
import { ChangeDetectorRef, Component, ElementRef, Input, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { TableComponent } from 'src/app/back-office/components/table/table.component';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import { UploadSettingComponent } from '../upload-setting/upload-setting.component';
@Component({
  selector: 'app-project-classify',
  templateUrl: './project-classify.component.html',
  styleUrls: ['./project-classify.component.scss', '../../../../components/table/table.component.scss']
})
export class ProjectClassifyComponent  extends TableComponent{
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private helper: HelperModule,
    private sharedService: SharedService,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer) {
      super(sanitizer);
  }
  @Input() userInfos!: { [key: string]: any };
  @Input() classifyData!: { [key: string]: any }[];
  @Input() projectInfos!: { [key: string]: any };
  @Input() platformInfos!: { [key: string]: any };

  ngOnInit() {
    console.log(this.projectInfos)
    this.setData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['classifyData'] && !changes['classifyData'].firstChange) {
      this.setData();
    }
  }
  setData(){
    if(this.classifyData && this.classifyData.length){
      this.dataSource = this.classifyData.map((row) => {
        /* row["expanded"] = false;
        row["checked"] = false;
        row["status"] = 'old';
        row["is_duplicate"] = row["duplicate"].split(',').length > 1? true: false;
        let duplicates = row["duplicate"].split(',').map((el:any) => parseInt(el)).filter((el:any) => el !== row["id"]).sort((a:any, b:any) => a - b).map((el:any) => {return {key: 'replace-'+el, value: 'Replace '+el}});
        row["actions"] = [{key: 'raw', value: 'Raw'},{key: 'archived', value: 'Archive'}].concat(duplicates); */
        return row;
      });
      
      this.showedRows = this.dataSource;
    }
    
  }
  contentOption(rowInfos:{ [x:string]: any },){
    const dialogRef = this.dialog.open(UploadSettingComponent, {
      width: '70%',
      position: {top: '30px' },
      data: {row_infos: rowInfos, user_infos: this.userInfos, platform_infos: this.platformInfos, project_infos: this.projectInfos}
    });
    dialogRef.afterClosed().subscribe((response:any) => {
      if(response && response && response.row){
        let result = response.row;
        this.dataSource = this.dataSource.map((element:any) => {
          if (element.id == result.id) {
            element.project_name = result.project_name;
            element.id_user_splitter = result.id_user_splitter;
            element.id_user_editor = result.id_user_editor;
            element.splitter = result.splitter;
            element.editor = result.editor;
            element.last_task = result.last_task;
            element.state = result.state;
            element.user_edit_splitter = result.user_edit_splitter
          }
          return element;
        });
        this.showedRows = this.showedRows.map((element:any) => {
          if (element.id == result.id) {
            element.project_name = result.project_name;
            element.id_user_splitter = result.id_user_splitter;
            element.id_user_editor = result.id_user_editor;
            element.splitter = result.splitter;
            element.editor = result.editor;
            element.last_task = result.last_task;
            element.state = result.state;
            element.user_edit_splitter = result.user_edit_splitter
          }
          return element;
        });
      }
      if(response && response.action == 'delete_project'){
        this.dataSource = this.dataSource.filter((el:any)=> el.id != response.id_project_deleted);
        this.showedRows = this.showedRows.filter((el:any)=> el.id != response.id_project_deleted);
      }
    });
  }
  setWaiting(value:any){
    this.sharedService.sendData({in_progress: value});
  }
  setUpdateStates(data:any){
    this.dataSource = this.dataSource.map((element:any) => {
      if (element.id_content == data.id_content) {
        element.state = data.state;
        element.active = data.active;
      }
      return element;
      });

    this.showedRows = this.showedRows.map((element:any) => {
      if (element.id_content == data.id_content) {
        element.state = data.state;
        element.active = data.active;
      }
      return element;
      });
  }
  setUpdatedContent(content:any){
    this.dataSource = this.dataSource.map((element:any) => {
      if (element.id_content == content.id_content) return this.platformInfos['origin'] == 'virtualcampus'? this.formatRowDisplayVca(element, content): this.formatRowDisplayVc(element, content)
      return element;
    });
    this.showedRows = this.showedRows.map((element:any) => {
      if (element.id_content == content.id_content) return this.platformInfos['origin'] == 'virtualcampus'? this.formatRowDisplayVca(element, content): this.formatRowDisplayVc(element, content)
      return element;
    });
  }
  formatRowDisplayVca(element:{ [x:string]: any }, content:{ [x:string]: any }){
    element['department']  =   content['department'];
    element['semester']    =   content['semester'];
    element['id_module']   =   content['id_module'];
    element['module']      =   content['module'];
    element['id_year']     =   content['id_year'];
    element['year']        =   content['year'];
    element['id_section']  =   content['id_section'];
    element['section']     =   content['section'];
    element['id_type']     =   content['id_type'];
    element['type']        =   content['type'];
    element['id_detail']   =   content['id_detail'];
    element['content_name']=   content['content_name'];
    element['content_url'] =   content['content_url'];
    element['state']       =   content['state'];
    element['active']      =   content['active'];
    element['duplicate']   =   content['duplicate'];
    element['state']       =   content['state'];
    return element;
  }
  formatRowDisplayVc(element:{ [x:string]: any }, content:{ [x:string]: any }){
    element['belongs']      =   content['belongs'];
    element['id_country']   =   content['id_country'];
    element['id_cycle']     =   content['id_cycle'];
    element['id_region']    =   content['id_region'];
    element['id_institution']=   content['id_institution'];
    element['id_study']     =   content['id_study'];
    element['study']        =   content['study'];
    element['id_class']     =   content['id_class'];
    element['class']        =   content['class'];
    element['id_module']    =   content['id_module'];
    element['module']       =   content['module'];
    element['id_year']      =   content['id_year'];
    element['year']         =   content['year'];
    element['id_period']    =   content['id_period'];
    element['period']       =   content['period'];
    element['id_section']   =   content['id_section'];
    element['section']      =   content['section'];
    element['id_type']      =   content['id_type'];
    element['type']         =   content['type'];
    element['id_detail']      =   content['id_detail'];
    element['content_name'] =   content['content_name'];
    element['content_url']  =   content['content_url'];
    element['state']        =   content['state'];
    element['active']       =   content['active'];
    element['duplicate']    =   content['duplicate'];
    element['state']        =   content['state'];
    return element;
  }
  toggleRow(row:any, clickedElm:any, rowTab:any){
    let toExpand = true;
    for (var tdTab of rowTab.querySelectorAll('.to_skip_element')){
      if(tdTab.contains(clickedElm)){
        toExpand = false;
        break;
      }
    }
    if(toExpand){
      row['expanded'] =! row['expanded'];
    }
  }
  applyFilterLocal(element:any, column:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applyFilter(element.value, column);
    this.cdRef.detectChanges();
  }
  applySortLocal(element:any, column:string, type:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applySort(element, column, type);
    this.cdRef.detectChanges();
  }
}

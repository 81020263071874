import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, Renderer2} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup, FormBuilder} from '@angular/forms';
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import { ProjectEditingComponent } from 'src/app/back-office/components/edit-project/project-editing/project-editing.component';
import config from 'config/default.json';
@Component({
  selector: 'app-vca-detail-content',
  templateUrl: './vca-detail-content.component.html',
  styleUrls: ['./vca-detail-content.component.scss']
})
export class VcaDetailContentComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiServiceModule,
    public sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private helper: HelperModule,
    public dialog: MatDialog
    ) {}
  config = config;
  @Input() rowInfos: { [x:string]: any } = new Map();
  @Input() userInfos: { [x:string]: any } = new Map();
  @Input() projectInfos: { [x:string]: any } = new Map();
  platformInfos:{[x: string]: any} = {origin: 'virtualcampus', root: 'VIRTUALCAMPUS'};
  
  pdfMode:string = 'edit';

  @Output() inProgress = new EventEmitter<any>();
  @Output() updateStates = new EventEmitter<any>();
  @Output() updateContent = new EventEmitter<any>();

  countryControl = new FormControl();
  institutionControl = new FormControl();
  structureControl = new FormControl();
  departmentControl = new FormControl();
  semesterControl = new FormControl();
  moduleControl = new FormControl();
  yearControl = new FormControl();
  sectionControl = new FormControl();
  typeControl = new FormControl();
  answerControl = new FormControl();
  nameControl = new FormControl();
  linkControl = new FormControl();

  searchCountryControl = new FormControl();
  searchInstitutionControl = new FormControl();
  searchStructureControl = new FormControl();
  searchDepartmentControl = new FormControl();
  searchSemesterControl = new FormControl();
  searchModuleControl = new FormControl();
  searchYearControl = new FormControl();
  searchSectionControl = new FormControl();
  searchTypeControl = new FormControl();
  searchAnswerControl = new FormControl();

  filteredCountries: { [x:string]: string|number|boolean }[] = [];
  filteredInstitutions: { [x:string]: string|number|boolean }[] = [];
  filteredStructures: { [x:string]: string|number|boolean }[] = [];
  filteredDepartments: { [x:string]: string|number|boolean }[] = [];
  filteredSemesters: { [x:string]: string|number|boolean }[] = [];
  filteredModules: { [x:string]: string|number|boolean }[] = [];
  filteredYears: { [x:string]: string|number|boolean }[] = [];
  filteredSections: { [x:string]: string|number|boolean }[] = [];
  filteredTypes: { [x:string]: string|number|boolean }[] = [];
  filteredAnswers: { [x:string]: string|number|boolean }[] = [];

  countries: { [x:string]: string|number|boolean }[] = [];
  institutions: { [x:string]: string|number|boolean }[] = [];
  structures: { [x:string]: string|number|boolean }[] = [];
  departments: { [x:string]: string|number|boolean }[] = [];
  semesters: { [x:string]: string|number|boolean }[] = [];
  modules: { [x:string]: string|number|boolean }[] = [];
  years: { [x:string]: string|number|boolean }[] = [];
  sections: { [x:string]: string|number|boolean }[] = [];
  types: { [x:string]: string|number|boolean }[] = [];

  showContentDetails:boolean = false;
  showContentAnswer:boolean = false;

  pdfRawData!:{ [x:string]: string|number|boolean };
  pdfEditData!:{ [x:string]: string|number|boolean };
  isPdfFileReady:boolean = false;

  zoomOut:boolean = false;
  zoomIn:boolean = false;

  contentDetails!:any;

  topicName: string = '';

  rowId: number = 0;
  source: string = 'content';

  ngOnInit(){
    console.log(this.rowInfos)
    this.source = this.rowInfos['source'];
    this.rowId = this.rowInfos['id_content'];
    this.cdRef.detectChanges();
    this.loadOneInfos();
    if(this.source == 'project'){
      console.log(this.rowInfos)
      this.pdfRawData = {url: this.config.master.ndapi.url + '/VIRTUALCAMPUS/'+this.rowInfos['raw_file_path'], height: 320};
      this.pdfEditData = {url: this.config.master.ndapi.url + '/VIRTUALCAMPUS/'+this.rowInfos['edit_file_pdf'], height: 320};
      this.isPdfFileReady = true;
    }else this.loadFile();

    this.applySearch();
    
      
  }
  applySearch(){
    this.searchCountryControl.valueChanges.subscribe((text) => {
      return this.filteredCountries = this.countries.filter(element => this.normalizeString(element['full_name']).includes(this.normalizeString(text)));
    });
    this.searchInstitutionControl.valueChanges.subscribe((text) => {
      return this.filteredInstitutions = this.institutions.filter(element => this.normalizeString(element['full_name']).includes(this.normalizeString(text)));
    });
    this.searchStructureControl.valueChanges.subscribe((text) => {
      return this.filteredStructures = this.structures.filter(element => this.normalizeString(element['full_name']).includes(this.normalizeString(text)));
    });
    this.searchDepartmentControl.valueChanges.subscribe((text) => {
      return this.filteredDepartments = this.departments.filter(element => this.normalizeString(element['full_name']).includes(this.normalizeString(text)));
    });
    this.searchSemesterControl.valueChanges.subscribe((text) => {
      return this.filteredCountries = this.semesters.filter(element => this.normalizeString(element['full_name']).includes(this.normalizeString(text)));
    });
    this.searchModuleControl.valueChanges.subscribe((text) => {
      return this.filteredModules = this.modules.filter(element => this.normalizeString(element['full_name']).includes(this.normalizeString(text)));
    });
    this.searchYearControl.valueChanges.subscribe((text) => {
      return this.filteredYears = this.years.filter(element => this.normalizeString(element['full_name']).includes(this.normalizeString(text)));
    });
    this.searchSectionControl.valueChanges.subscribe((text) => {
      return this.filteredSections = this.sections.filter(element => this.normalizeString(element['full_name']).includes(this.normalizeString(text)));
    });
    this.searchTypeControl.valueChanges.subscribe((text) => {
      return this.filteredTypes = this.types.filter(element => this.normalizeString(element['full_name']).includes(this.normalizeString(text)));
    });
    this.searchAnswerControl.valueChanges.subscribe((text) => {
      return this.filteredAnswers = this.contentDetails['answer'].filter((element:any) => this.normalizeString(element['full_name']).includes(this.normalizeString(text)));
    });
  }
  selectedElement(selectedOptions: FormControl, element: { [x:string]: string|number|boolean }): boolean {
    return Array.isArray(selectedOptions.value) && selectedOptions.value.includes(element['id']);
  }
  normalizeString(str:any){
    return str.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  }
  resetDetails(){
    let section = this.sections.find((element) => element['id'] == this.sectionControl.value)?.['full_name'].toString();
    let type = this.types.find((element) => element['id'] == this.typeControl.value)?.['full_name'].toString();
    this.manageDetailsDisplay(section, type);
  }
  switchEditRaw(){
    if(this.source == 'content'){
      this.pdfRawData['refresh'] = true;
      this.pdfEditData['refresh'] = true;
      this.loadFile()
    }
    this.pdfMode = this.pdfMode == 'raw'? 'edit': 'raw';
  }

  loadFile(){
    this.inProgress.emit(true);
      let data = {
        table: 'generate_files_urls',
        files_path: {raw: 'VIRTUALCAMPUS/'+this.rowInfos['raw_file_path'], edit: 'VIRTUALCAMPUS/'+this.rowInfos['edit_file_pdf']}
      }
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));
      this.apiService.queryData(config.service.cloud.url + '/generate_files_urls', formData).subscribe((response:any)=>{
        this.isPdfFileReady = true;
        this.pdfRawData = {url: response.raw.file_url, height: 320};
        this.pdfEditData = {url: response.edit.file_url, height: 320};
        this.inProgress.emit(false);
      })
  }
  applyUpdate(){
    let moduleId = this.moduleControl.value;
    let yearId = this.yearControl.value;
    let sectionId = this.sectionControl.value;
    let typeId = this.typeControl.value;
    let detailId = this.answerControl.value;
    let nameContent = this.nameControl.value;
    let link = this.linkControl.value;

    let moduleNames = this.contentDetails.answer.filter((el:any) => el.id != this.rowId).map((el:any)=> el.full_name);

    let validation = this.showContentDetails && this.showContentAnswer && !detailId? {status: false, message: 'The "Answer of" is Mandatory...'}: (
      this.showContentDetails && !this.showContentAnswer && (!nameContent || moduleNames.includes(nameContent))? {status: false, message: 'Name should already exist or empty...'}: {status: true, message: 'OK'});


    if(validation.status){
      let content = {
        id_content: this.rowId,
        id_module:  moduleId,
        id_year:    yearId,
        id_section: sectionId,
        id_type:    typeId,
        id_detail:  detailId,
        content_name:       nameContent,
        content_url:       link
      }
      let data = {
        source: this.source,
        state: 'classified',
        state_checked: true,
        user_infos: this.userInfos,
        row_infos: this.rowInfos,
        content: content
      }
      this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/web/content/update_content_classification', data).subscribe((response:any)=>{
        //console.log(response)
        this.updateContent.emit(response.content);
        this.helper.showToast(response.message);
      });
    }else this.helper.showToast(validation.message);

    
  }
  updateState(state:string, isChecked:boolean){
    this.inProgress.emit(true);
    let data = {
      source: this.source,
      state: state,
      state_checked: isChecked,
      row_infos: this.rowInfos,
      user_infos: this.userInfos
    }
    if(this.rowInfos['active'] && this.source == 'content'){
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '50%',
        position: {top: '30px' },
        data: {page: 'confirmation', text: 'This change will affect Students subscriptions. Continue ?'}
      });
      dialogRef.afterClosed().subscribe((toContinue:boolean) => {
        if(toContinue) this.applyUpdateState(config.master.ndapi.url + '/virtualcampus/web/content/update_content_state', data);
      });
    }else this.applyUpdateState(config.master.ndapi.url + '/virtualcampus/web/content/update_content_state', data);
  }
  applyUpdateState(url:string, formData:any){
    this.apiService.queryData(url, formData).subscribe((response:any)=>{
      if(response.status){
        this.updateStates.emit({state: response.state, active: response.active, id_content: this.rowId})
      }
      this.helper.showToast(response.message);
      this.inProgress.emit(false);
    })
  }
  loadOneInfos(){
    let data = {
      id_content: this.source == 'content'? this.rowInfos['id_content']: null,
      module: {id: this.rowInfos['id_module'], id_year: [this.rowInfos['id_year']], id_section: [this.rowInfos['id_section']], id_type: [this.rowInfos['id_type']]},
      source: this.source
    }; 
    this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/one_module_content_parents', data).subscribe((data:any)=>{
      this.countries = data.country;
      this.institutions = data.institution;
      this.structures = data.structure;
      this.departments = data.department;

      this.semesters = data.semester;
      this.modules = data.module;
      this.years = data.year;
      this.sections = data.section;
      this.types = data.type;
      this.contentDetails = data.detail;
      this.manageDetailsDisplay(this.rowInfos['section'], this.rowInfos['type']);

      this.filteredCountries = this.countries;
      this.filteredInstitutions = this.institutions;
      this.filteredStructures = this.structures;
      this.filteredDepartments = this.departments;
      this.filteredSemesters = this.semesters;
      this.filteredModules = this.modules;
      this.filteredYears = this.years;
      this.filteredSections = this.sections;
      this.filteredTypes = this.types;
      this.filteredAnswers = this.contentDetails['answer'];
      console.log(this.contentDetails)

      this.countryControl.setValue(this.countries.filter(element => element['selected']).map(el => el['id']));
      this.institutionControl.setValue(this.institutions.filter(element => element['selected']).map(el => el['id']));
      this.structureControl.setValue(this.structures.filter(element => element['selected']).map(el => el['id']));
      this.departmentControl.setValue(this.departments.filter(element => element['selected']).map(el => el['id']));
      this.semesterControl.setValue(this.semesters.filter(element => element['selected']).map(el => el['id']));
      this.moduleControl.setValue(this.modules.find((element) => element['selected'])!['id']);

      this.yearControl.setValue(this.rowInfos['id_year']);
      this.sectionControl.setValue(this.rowInfos['id_section']);
      this.typeControl.setValue(this.rowInfos['id_type']);
      if(this.contentDetails['answer'].length) this.answerControl.setValue(this.rowInfos['id_detail']);
      this.nameControl.setValue(this.rowInfos['content_name']);
      this.linkControl.setValue(this.rowInfos['content_url']);
    })
  }
  updateInstitution(){
    this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/institution', {parent_ids: this.countryControl.value}).subscribe((data:any)=>{
      this.institutions = data;
      this.filteredInstitutions = this.institutions;
      console.log(data)
    });
  }
  updateStructure(){
    this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/structure', {parent_ids: this.institutionControl.value}).subscribe((data:any)=>{
      this.structures = data;
      this.filteredStructures = this.structures;
    });
  }
  updateDepartment(){
    this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/department', {parent_ids: this.structureControl.value}).subscribe((data:any)=>{
      this.departments = data;
      this.filteredDepartments = this.departments;
    });
  }
  updateSemester(){
    this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/semester', {parent_ids: this.departmentControl.value}).subscribe((data:any)=>{
      this.semesters = data;
      this.filteredSemesters = this.semesters;
    });
  }
  updateModule(){
    this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/module', {departments: this.departmentControl.value, semesters: this.semesterControl.value}).subscribe((data:any)=>{
      this.modules = data;
      this.filteredModules = this.modules;
    });
  }
  updateDetail(){
    this.apiService.queryData(config.master.ndapi.url + '/virtualcampus/infos_child/detail', {id_module: this.moduleControl.value}).subscribe((data:any)=>{
      this.contentDetails = data;
      this.filteredAnswers = this.contentDetails['answer'];
      this.resetDetails();
    });
  }
  manageDetailsDisplay(section:any, type:any){
    this.showContentDetails = ['Cours','Exercices','Travaux Pratiques','Vidéo'].includes(section)? true: false;
    this.showContentAnswer = ['Corrigé'].includes(type)? true: false;
    this.cdRef.detectChanges();
  }
  
  lunchEdition(){
    console.log(this.rowInfos)
    const dialogRef = this.dialog.open(ProjectEditingComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {project: {last_task: 'task-editing'}, content_data: [this.rowInfos], user_infos: this.userInfos, platform_infos: this.platformInfos, source: this.source}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}

import { Component, Input } from '@angular/core';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'app-advertisment',
  templateUrl: './advertisment.component.html',
  styleUrls: ['./advertisment.component.scss', '../../components/table/table.component.scss']
})
export class AdvertismentComponent extends TableComponent{
  @Input() userInfos: { [x:string]: any } = new Map();
  @Input() data:{[x: string]: any}[]= [];
  @Input() platformInfos: { [x:string]: any } = new Map();
}

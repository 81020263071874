import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import config from 'config/default.json';
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UploadContentComponent } from 'src/app/back-office/components/upload-content/upload-content.component';

@Component({
  selector: 'app-vcl-upload-content',
  templateUrl: './vcl-upload-content.component.html',
  styleUrls: ['./vcl-upload-content.component.scss']
})
export class VcUploadContentComponent {
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private apiService: ApiServiceModule,
    private helper: HelperModule,
    public dialog: MatDialog,
    private sharedService: SharedService
    ) {}
  @Input() userInfos: { [x:string]: any } = new Map();
  @ViewChild('appUploadContent', { static: true }) appUploadContent!: UploadContentComponent;
  pageId = 'vcl-upload-content'
  modes:{ [x:string]: string|number }[] = [{id:1, key: 'raw', value: 'Raw'}, {id: 2, key: 'video', value: 'Video'}];
  platformInfos:{[x: string]: any} = {origin: 'virtualclass', root: 'VIRTUALCLASS'}
  filterData: {[x: string]: any} = {}

  ngOnInit(){

  }
  filterUploadContent(selectedData: any){
    let filterData = selectedData.filter_data;
    let modeSelected = selectedData.mode;
    modeSelected = modeSelected?.length? this.modes.filter((row:any) => modeSelected.includes(row['id'])).map(el => el['key']): this.modes.map(el => el['key']);
    let pathsIds = [filterData.country?.id || null, filterData.cycle?.id || null, filterData.region?.id || null, filterData.institution?.id || null];

    if(selectedData.action == 'user' && pathsIds.filter((el:any)=> el)?.length){
      let importPathName = [filterData.country?.name || '', filterData.cycle?.name || '', filterData.region?.name || '', filterData.institution?.name || ''];
      let importPath =  importPathName.filter((el:any)=>el != '')
      let userInfos = this.userInfos;
      userInfos['navigation_infos'] = null;
      let data = {
        parents_id: pathsIds,
        file_path_list: importPath,
        user_infos: userInfos,
        platform_infos: this.platformInfos
      }
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '60%',
        position: {top: '30px' },
        data: {page: 'confirmation', text: 'Your are about to upload contents inside: '+importPath.join(' > '), }
      });
      dialogRef.afterClosed().subscribe((toContinue:boolean) => {
        if(toContinue){
          this.appUploadContent.uploadContent(data);
        }
      });
    }
    /* console.log(filterData)
    filterData['mode'] = filterData['mode'] && filterData['mode'].length? this.modes.filter((row:any) => filterData['mode'].includes(row['id'])).map(el => el['key']): this.modes.map(el => el['key']);
    let parents = filterData.parents;
    if(parents.country.length == 1 && parents.cycle.length == 1 && filterData.value.length){
      let parentsId = [parents.country?.[0]?.id || null, parents.cycle?.[0]?.id || null, parents.region?.[0]?.id || null, parents.institution?.[0]?.id || null];
      console.log(parentsId)
      let importPathList = [parents.country?.[0]?.name || '', parents.cycle?.[0]?.name || '', parents.region?.[0]?.name || '', parents.institution?.[0]?.name || ''];
      let importPath =  importPathList.filter((el:any)=>el != '')
      
    }else this.helper.showToast("Your filter is empty or contain some muliselects...");  */
  }
}

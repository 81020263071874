import { NgModule, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, Inject, Renderer2, ElementRef} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import config from 'config/default.json';
declare var $: any;

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class ClassifyModule { 
  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private helper: HelperModule,
    private apiService: ApiServiceModule,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    ) {}
    config = config;
    that: any;
    identifyModule:any;
    pdfContainer: any;

    init(that:any){
      this.that = that;
    }
    showClassified(){
      this.that.cleanedActive = false;
      this.that.editedActive = false;
      this.that.classifiedActive = true;
      this.loadClassifyData();
    }
    loadClassifyData(){
      this.that.inProgress = true;
      let project = this.that.projectInfos;
      let data = {
        platform_infos: this.that.platformInfos,
        id_project: project.id,
        id_structure: project.id_structure,
        vcl_params: this.that.platformInfos['origin'] == 'virtualclass'? [{key: 'con.id_country', value: project.id_country}, {key: 'con.id_cycle', value: project.id_cycle}, {key: 'con.id_region', value: project.id_region}, {key: 'con.id_institution', value: project.id_institution}]: null,
      };
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/project/load_classify_data', data).subscribe((response:any)=>{
        this.that.inProgress = false;
        this.that.projectInfos['last_task'] = 'task-classified'
        this.that.classifyData = response;
        this.that.activatePage('task-classified');
      })
    }
    
    
}

<div>
    <div style="text-align:left; padding: 20px;">
        <input class="float-right col-input" style="width: 180px; float:right;" (keyup)="applyFilterLocal($event.target, 'all_column')" placeholder="Search">
        <p>Total: {{this.showedRows.length}}</p>
    </div>
    <div class="table_div">
        <table>
            <thead>
            <tr>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id', 'number')"></mat-icon>
                    <div class="column-name">Id</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'promo_name', 'string')"></mat-icon>
                    <div class="column-name">Name</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'promo_name')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'promo_type', 'string')"></mat-icon>
                    <div class="column-name">Type</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'promo_type')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'price', 'string')"></mat-icon>
                    <div class="column-name">Price</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'price')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'credit', 'number')"></mat-icon>
                    <div class="column-name">Credit</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'credit')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'product_name', 'string')"></mat-icon>
                    <div class="column-name">Product</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'product_name')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'begin_date', 'date')"></mat-icon>
                    <div class="column-name">Begin date</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'begin_date')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'expiration_date', 'string')"></mat-icon>
                    <div class="column-name">Expiration date</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'expiration_date')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'nb_student', 'number')"></mat-icon>
                    <div class="column-name">Nb Student</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'nb_student')"></div>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody #row_tbody *ngFor="let row of showedRows; let i = index">
                <tr (click)="toggleRow(row, $event.target, row_tbody)" [ngClass]="{'highlight-previous': row['source']=='content'}">
                    <td>{{ row['id'] }}</td>
                    <td>{{ row['promo_name'] }}</td>
                    <td>{{ row['promo_type'] }}</td>
                    <td>{{ row['price'] }}</td>
                    <td style="white-space: nowrap;">{{ row['credit'] }}</td>
                    <td>{{ row['product_name'] }}</td>
                    <td >{{ row['begin_date']}}</td>
                    <td >{{ row['expiration_date']}}</td>
                    <td >{{ row['nb_student']}}</td>
                    <td></td>
                </tr>
                <tr class="expand_detail" *ngIf="row['expanded']">
                    <td colspan="10">
                        <app-detail-promotion [rowInfos]="row" [userInfos]="userInfos" (inProgress)="setWaiting($event)" (updatePromotion)="setUpdatePromotion($event)"></app-detail-promotion>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="showedRows.length === 0">
                <tr ><td colspan="10" style="text-align: center;">No matching records found</td></tr>
            </tbody>
        </table>
    </div>
</div>
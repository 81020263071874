<div fxLayout="row" fxLayout.xs="column" style="padding: 5px;">
    <div class="center-vertical" fxFlex="25%">
        <mat-select placeholder="User" [formControl]="userControl">
            <input class="col-input-filter" [formControl]="userInputControl">
            <mat-option [value]="0">Choose</mat-option>
            <mat-option [value]="result['id_user']" *ngFor="let result of allUsers; let i = index">{{result['first_name']}} {{result['last_name']}} ({{result['email']}})</mat-option>
        </mat-select>
    </div>
    <div class="separator"></div>
    <div class="center-vertical" fxFlex="25%">
        <div class="date-input">
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [formControl]="startDateInputControl" placeholder="Start">
                <input matEndDate [formControl]="endDateInputControl" placeholder="End">
            </mat-date-range-input>
            <mat-icon matDatepickerToggleIcon fontIcon="close_small" style="font-size: 18px; display: flex; align-items: center;" (click)="resetDate()"></mat-icon>
            <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
    </div>
    <div class="separator"></div>
    <div class="center-vertical" fxFlex="25">
        <div style="position: relative; display: flex; margin-left: auto; align-items: center; ">
            <span class="switch-toggle" [ngClass]="{'switch-checked': onlyMyProject}" (click)="toggleOnlyMyProject()">
              <small></small>
            </span>
            <label class="filter-label">My projects</label>
        </div>
    </div>
    <div class="separator"></div>
    <div class="class-mat-field" fxFlex="25%"  fxLayoutAlign="center center">
        <button class="button-validate-small" (click) = "chargeData()">Validate</button>
    </div>
</div>

<div class="horizontal" fxLayout="row" style="padding: 5px;">
    <div class="center-vertical" fxFlex="50%">Project: {{this.showedRows.length}} - Content: {{totalContent}} - Edited: {{totalEdited}} - Classified: {{totalCLassified}} - Words: {{totalWords}}</div>
    <div class="center-vertical" fxFlex="50%">
        <input class="float-right col-input" style="width: 180px; margin-left: auto" (keyup)="applyFilterLocal($event.target, 'all_column')" placeholder="Search">
    </div>
</div>

<div class="table_div">
    <table>
        <thead>
            <tr>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'id', 'number')"></mat-icon>
                    <div class="column-name">Id</div>
                    <input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'id')">
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'creator', 'string')"></mat-icon>
                    <div class="column-name">Creator</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'creator')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'splitter', 'string')"></mat-icon>
                    <div class="column-name">Splitter</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'splitter')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'editor', 'string')"></mat-icon>
                    <div class="column-name">Editor</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'editor')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'project_name', 'string')"></mat-icon>
                    <div class="column-name">Project Name</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'project_name')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'nb_content', 'number')"></mat-icon>
                    <div class="column-name">Nb Content</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'nb_content')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'nb_cleaned', 'number')"></mat-icon>
                    <div class="column-name" style="white-space: nowrap;">Cont. Edited</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'nb_cleaned')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'nb_classified', 'number')"></mat-icon>
                    <div class="column-name">Cont. Classified</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'nb_classified')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'nb_imported', 'number')"></mat-icon>
                    <div class="column-name">Nb uploaded</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'nb_imported')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'nb_word', 'number')"></mat-icon>
                    <div class="column-name">Nb Words</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'nb_word')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'last_edition', 'date')"></mat-icon>
                    <div class="column-name">Last Edition</div>
                    <div><input matInput class="col-input" (keyup)="applyFilterLocal($event.target, 'last_edition')"></div>
                </th>
                <th>
                    <mat-icon  sorted="" fontIcon="sort" (click)="applySortLocal($event.target, 'state', 'string')"></mat-icon>
                    <div class="column-name">State</div>
                    <mat-select placeholder="Choose" style="height: 22px;" (selectionChange)="applyFilterLocal($event, 'state')">
                        <mat-option [value]="''">ALL</mat-option>
                        <mat-option [value]="'editing'">editing</mat-option>
                        <mat-option [value]="'splitting'">splitting</mat-option>
                        <mat-option [value]="'unknown'">unassigned</mat-option>
                        
                    </mat-select>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody #row_tbody *ngFor="let row of showedRows; let i = index">
            <tr>
                <td>{{ row['id'] }}</td>
                <td>{{ row['creator'] }}</td>
                <td>{{ row['splitter'] }}</td>
                <td>{{ row['editor'] }}</td>
                <td>{{ row['project_name'] }}</td>
                <td>{{ row['nb_content'] }}</td>
                <td style="padding: 1px;">
                    <div class="progress" title="{{row['edited_percent']}}%">
                        <div class="progress-bar" [ngStyle]="{'width': row['edited_percent'] + '%', 'background-color': 'var(--primary1)'}"></div>
                        <span>{{row['edited_percent']}}% ({{row['nb_edited']}})</span>
                    </div>
                </td>
                <td style="padding: 1px;">
                    <div class="progress" title="{{row['classified_percent']}}%">
                        <div class="progress-bar" [ngStyle]="{'width': row['classified_percent'] + '%', 'background-color': 'var(--primary2)'}"></div>
                        <span>{{row['classified_percent']}}% ({{row['nb_classified']}})</span>
                    </div>
                </td>
                <td style="padding: 1px;">
                    <div class="progress" title="{{row['imported_percent']}}%">
                        <div class="progress-bar" [ngStyle]="{'width': row['imported_percent'] + '%', 'background-color': 'var(--primary1)'}"></div>
                        <span>{{row['imported_percent']}}% ({{row['nb_imported']}})</span>
                    </div>
                </td>
                <td style="white-space: nowrap;">{{ row['nb_word']}}</td>
                <td style="white-space: nowrap;">{{ row['last_edition']}}</td>
                <td style="white-space: nowrap;">{{ row['state'] }}</td>
                <td class="to_skip_element">
                    <div style="display: flex;">
                        <mat-icon  sorted="" fontIcon="open_in_new" style="color: gray; font-size: 20px;" (click)="openSplitingEditing(row)"></mat-icon>
                        <mat-icon  sorted="" fontIcon="more_vert" style="color: gray; font-size: 20px;" (click)="projectOption(row)"></mat-icon>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="showedRows.length === 0">
            <tr ><td colspan="14" style="text-align: center;">No matching records found</td></tr>
        </tbody>
    </table>
</div>
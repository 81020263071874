import { NgModule, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, Inject, Renderer2, ElementRef} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { DialogComponent } from 'src/app/back-office/components/dialog/dialog.component';
import config from 'config/default.json';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CleanModule { 
  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private helper: HelperModule,
    private apiService: ApiServiceModule,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    ) {}

    that: any;
    config = config;
    editorModule:any;
    pdfContainer: any;

    init(that:any, editorModule:any){
      this.that = that;
      this.editorModule = editorModule;
    }
    showCleaned(){
      if(this.that.contentData.length && this.that.contentData[0].clean_file_path){
        this.that.splitingActive = false;
        this.that.splitedActive = false;
        this.that.cleanedActive = true;

        this.that.activatePage('task-cleaned');

        this.pdfContainer = this.elRef.nativeElement.querySelector('.show_content_cleaned');
        this.showPages(this.that.contentData);
      }else this.helper.showToast('Cleaned content not founed...');
      
    }
    computeAutoEditing(){
      if(this.that.projectInfos['state'] == 'editing'){
        if( this.that.contentData.length){
          const dialogRef = this.dialog.open(DialogComponent, {
            width: '50%',
            position: {top: '30px' },
            data: {page: 'confirmation', text: 'This action will erase your previous Conversion Build... Continue ?'}
          });
          dialogRef.afterClosed().subscribe((toContinue:boolean) => {
            if(toContinue){
              this.that.inProgress = true;
              let contentsInfos = {
                platform_infos: this.that.platformInfos,
                user_infos: this.that.userInfos,
                project_infos: this.that.projectInfos,
                content_data: this.that.contentData
              };
              const formData = new FormData();
              formData.append('data', JSON.stringify(contentsInfos));
              
              this.apiService.queryData(config.master.flapi.url + '/enovsky/edit_content', formData).subscribe((response:any)=>{
                this.that.inProgress = false;
                if(response.status){
                  this.that.projectInfos['last_task'] = 'task-editing'
                  response.data.forEach((row:any) => {
                    let objIndex = this.that.contentData.findIndex(((element:any) => element.id_content == row.at(-1)));
                    this.that.contentData[objIndex].edit_file_pdf = row[0];
                    this.that.contentData[objIndex].edit_file_docx = row[1];
                  });
                  this.editorModule.showEdited();
                }else this.helper.showToast(response.message);
              })
            }
          })
        }else this.helper.showToast('Edited content not created...');
      }else this.helper.showToast('Assign this project to an editor first...');
      
    }

    async showPages(data: { [x:string]: any }[]){
      this.that.inProgress = true;
      this.that.removeChildsFromElement(this.pdfContainer);
      this.that.PDFJS.GlobalWorkerOptions.workerSrc = 'assets/pdf/pdf.worker.js';
      let totalDocuments = data.length;
      for(let index = 0; index < totalDocuments; index++){
        let url = this.config.master.ndapi.url + '/' + this.that.platformInfos['root']+'/'+data[index]['clean_file_path'];
        
        //Get file
        try {
          const pdf = await this.that.PDFJS.getDocument(url).promise;
          const totalPages = pdf.numPages;
          for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            let that = this;
            const page = await pdf.getPage(pageNumber);
            //Prepare Canvas
            var scale = 0.5;
            var viewport = page.getViewport({ scale: scale});
            var canvas = that.renderer.createElement('canvas');
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            let canvasDivContainer = that.renderer.createElement('div');
            let canvasDiv = that.renderer.createElement('div');
            canvasDiv.style.width = viewport.width+'px';
            canvasDiv.style.height = viewport.height+'px';
            canvasDivContainer.classList.add("canvasDivContainerClean");
            canvasDiv.classList.add("canvasDiv");

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            //Header
            let canvasDivParent = that.renderer.createElement('div');
            let header = that.renderer.createElement('div');
            let middleSide = that.renderer.createElement('div');
            let leftSide = that.renderer.createElement('div');
            let rightSide = that.renderer.createElement('div');
            let separator = that.renderer.createElement('div');

            header.classList.add("page_header");
            leftSide.classList.add("page_header_left");
            middleSide.classList.add("page_header_middle");
            rightSide.classList.add("page_header_right");
            separator.classList.add("line_separator");
            
            middleSide.textContent = 'Content: '+(index + 1)+' - Total pages: '+totalPages;
            
            var renderContext = { canvasContext: context, viewport: viewport };
            await page.render(renderContext).promise;
            header.append(leftSide, middleSide, rightSide);

            canvasDiv.append(canvas);
            canvasDivContainer.appendChild(canvasDiv)
            
            if(pageNumber == 1) canvasDivParent.append(header, canvasDivContainer);
            else canvasDivParent.append(separator, canvasDivContainer);


            that.pdfContainer.appendChild(canvasDivParent);
          }
          this.that.inProgress = false;
        }catch (error) {
          // Handle errors here
          console.error(error);
        }
  
  
      
    }
    
  }
}

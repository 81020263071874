import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import config from 'config/default.json';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';

@Component({
  selector: 'app-vcl-create-project',
  templateUrl: './vcl-create-project.component.html',
  styleUrls: ['./vcl-create-project.component.scss']
})
export class VcCreateProjectComponent{
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    private apiService: ApiServiceModule,
    private sharedService: SharedService) {
  }
  @Input() userInfos: { [x:string]: any } = new Map();
  pageId = 'vcl-create-project'
  pdfData: { [x:string]: any } = new Map();
  modes:{ [x:string]: string|number }[] = [{id:1, key: 'raw', value: 'Raw'}, {id: 2, key: 'splited', value: 'Split'}];
  dataSource:{[x: string]: any}[] = [];
  platformInfos:{[x: string]: any} = {origin: 'virtualclass', root: 'VIRTUALCLASS'}

  ngOnInit(): void {

  }
  loadSplitContentData(selectedData:any){
    this.sharedService.sendData({in_progress: true});
    let filterData = selectedData.filter_data;
    let modeSelected = selectedData.mode;
    modeSelected = modeSelected?.length? this.modes.filter((row:any) => modeSelected.includes(row['id'])).map(el => el['key']): this.modes.map(el => el['key']);
    let condition = [{key: 'id_country', value: filterData.country?.id || null}, {key: 'id_cycle', value: filterData.cycle?.id || null}, {key: 'id_region', value: filterData.region?.id || null}, {key: 'id_institution', value: filterData.institution?.id || null}];
    condition = condition.filter((el:any)=> el.value)
    let data = {
        filter_data: condition,
        mode: modeSelected
    }; 
    if(condition.length){
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/raw-content/load_vcl', data).subscribe((response)=>{
        this.dataSource = response;
        this.sharedService.sendData({in_progress: false});
      });
    }
    
  }
}

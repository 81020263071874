import { Component, Output, EventEmitter, ElementRef} from '@angular/core';
import {ApiServiceModule} from 'src/app/back-office/modules/api-service.module';
import config from 'config/default.json';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(
    private elRef:ElementRef,
    private apiService: ApiServiceModule,
    private formBuilder: FormBuilder,
    private helper: HelperModule,
    private sharedService: SharedService,
    ) {}
  formGroup!: FormGroup;
  @Output() createAccountEvent = new EventEmitter<any>();
  @Output() connexionEvent = new EventEmitter<any>();
  message = '';
  isStudent = false;
  ngOnInit(){
    this.formGroup = this.formBuilder.group({
      mail: ['', [Validators.required]],
      password: ['', [Validators.required]],
      user: 'user'
    });
  }
  authenticate(){
    if (this.formGroup.valid) {
      const data = this.formGroup.value;
      this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/administration/user_login', data).subscribe((response:any)=>{
        this.helper.showToast(response.message);
        if(response.status){
          //this.sharedService.sendData({user_infos: response.user_infos});
          this.connexionEvent.emit(response.user_infos)
        }else this.helper.showToast(response.message);
      }) 
    }else this.helper.showToast("You have some errors in the form..");
    
  }
  registration(){
    this.createAccountEvent.emit();
  }
}

import { ChangeDetectorRef, Component, ElementRef, Input, SimpleChanges } from '@angular/core';
import { TableComponent } from '../table/table.component';
import { SharedService } from '../../modules/shared-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss', '../../components/table/table.component.scss']
})
export class PromotionComponent extends TableComponent{
  constructor(
    private elRef:ElementRef,
    private cdRef: ChangeDetectorRef,
    private sharedService: SharedService,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer) {
      super(sanitizer);
  }
  @Input() userInfos: { [x:string]: any } = new Map();
  @Input() data:{[x: string]: any}[]= [];
  @Input() platformInfos: { [x:string]: any } = new Map();

  

  ngOnInit() {
    this.setData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange) {
      this.setData();
    }
  }
  setData(){
    this.dataSource = this.data.map((row) => {
      return row;
    });
    
    this.showedRows = this.dataSource;
    
  }
  
  setUpdatePromotion(data:any){
    this.dataSource = this.dataSource.map((element:any) => {
      if (element.id == data.id) {
        element.begin_date = data.begin_date;
        element.expiration_date = data.expiration_date;
      }
      return element;
      });

    this.showedRows = this.showedRows.map((element:any) => {
      if (element.id == data.id) {
        element.begin_date = data.begin_date;
        element.expiration_date = data.expiration_date;
      }
      return element;
      });
  }
  toggleRow(row:any, clickedElm:any, rowTab:any){
    let toExpand = true;
    for (var tdTab of rowTab.querySelectorAll('.to_skip_element')){
      if(tdTab.contains(clickedElm)){
        toExpand = false;
        break;
      }
    }
    if(toExpand){
      row['expanded'] =! row['expanded'];
    }
  }
  applyFilterLocal(element:any, column:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applyFilter(element.value, column);
    this.cdRef.detectChanges();
  }
  applySortLocal(element:any, column:string, type:string){
    let tableBody = this.elRef.nativeElement.querySelector('tbody');
    this.removeDetailsRows(tableBody);
    this.applySort(element, column, type);
    this.cdRef.detectChanges();
  }
  setWaiting(value:any){
    this.sharedService.sendData({in_progress: value});
  }
}

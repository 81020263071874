import { Component } from '@angular/core';

@Component({
  selector: 'app-detail-advertisment',
  templateUrl: './detail-advertisment.component.html',
  styleUrls: ['./detail-advertisment.component.scss']
})
export class DetailAdvertismentComponent {

}

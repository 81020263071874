import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiServiceModule } from 'src/app/back-office/modules/api-service.module';
import { HelperModule } from 'src/app/back-office/modules/helper.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/back-office/modules/shared-service.service';
import config from 'config/default.json';
import { DialogComponent } from '../../components/dialog/dialog.component';
declare const DocsAPI: any;
@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent {
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    public helper: HelperModule,
    public dialog: MatDialog,
    private apiService: ApiServiceModule,
    private sharedService: SharedService,) {
  }
  @Input() userInfos: { [x:string]: any } = new Map();
  editConfig:any;
  toEdit:boolean = false;
  editor: any;
  
  ngOnInit(): void {
    this.loadConfig();
  }
  toggleEditMode(){
    this.toEdit = !this.toEdit;
    if(this.editor) {
      this.editor.destroyEditor();
    }
    this.editConfig.editorConfig.mode = this.toEdit? 'edit': 'view';
    this.editor = new DocsAPI.DocEditor('editor-container', this.editConfig);
  }
  playLeaning(){
    const dialogRef = this.dialog.open(DialogComponent, {
      //width: '70%',
      position: {top: '30px' },
      data: {page: 'video_show', title: 'Learning Edit Content', url: 'https://ndapi.master.enovsky.com/RESSOURCES/learning_content_edition.mp4'}//https://enovsky.com/videos/campus_virtuel_presentation.mov
    });
    dialogRef.afterClosed().subscribe((toContinue:boolean) => {
      if(toContinue){
        
      }
    });
  }
  
  loadConfig(){
    let data = {
      target: 'ndapi',
      id_content: null,
      id_project: null,
      file_url: config.master.ndapi.url + '/RESSOURCES/content_integration.docx',
      user_infos: this.userInfos
    }; 

    this.sharedService.sendData({in_progress: true});
    this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/content/onlyoffice_conf', data).subscribe((response:any)=>{
      this.editConfig = response;
      this.editConfig.editorConfig.mode = 'view'
      this.editor = new DocsAPI.DocEditor('editor-container', this.editConfig);
      this.sharedService.sendData({in_progress: false});
    })
  }
}

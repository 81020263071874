import { ChangeDetectorRef, Component, ElementRef, Input, SimpleChanges } from '@angular/core';
import { ApiServiceModule } from '../../modules/api-service.module';
import { SharedService } from '../../modules/shared-service.service';
import config from 'config/default.json';

@Component({
  selector: 'app-vcl-advertisment',
  templateUrl: './vcl-advertisment.component.html',
  styleUrls: ['./vcl-advertisment.component.scss']
})
export class VclAdvertismentComponent {
  constructor(
    private elRef:ElementRef,
    public cdRef: ChangeDetectorRef,
    private apiService: ApiServiceModule,
    private sharedService: SharedService) {
  }
  @Input() userInfos: { [x:string]: any } = new Map();
  pageId = 'vcl-advertisment'
  dataSource:{[x: string]: any}[] = [];
  platformInfos:{[x: string]: any} = {origin: 'virtualclass', root: 'VIRTUALCLASS'}
  

  ngOnInit(): void {
    if(Object.keys(this.userInfos).length) this.loadAdvertismentData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userInfos'] && !changes['userInfos'].firstChange) {
      this.loadAdvertismentData();
    }
  }
  loadAdvertismentData(){
    this.sharedService.sendData({in_progress: true});
    this.apiService.queryData(config.master.ndapi.url + '/enovsky/web/advertisment/load', {id_user: this.userInfos['id_user'], platform_infos: this.platformInfos}).subscribe((response)=>{
      this.dataSource = response;
      this.sharedService.sendData({in_progress: false});
    })
  }
}
